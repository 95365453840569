.ChartComponent {
    .list-chart {
        height: calc(100vh - 70px);
        overflow: auto;
        width: 60%;
        margin: auto;
        .chart-container {
            border-left: 2px solid;
            border-top: 2px solid;
            border-bottom: 4px solid;
            border-right: 2px solid;;
            color: #0000001f;
            border-radius: 5px;
            margin: 10px 10px 50px;
            p {
                text-align: center;
                color: #606060;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                margin-bottom: 5px;
                padding-top: 10px;
                font-weight: bold;
                margin: 0;
                padding-top: 10px;
            }
            .chart-item {
                padding: 0 20px;
                height: 300px;
            }
        }
    }
}