@import "./variables";
@import "./table";
@import "./btn";
body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $c-text;
  background-color: $c-background;
}

input {
  text-indent: 10px;
  border-radius: $border-radius;
  border: $border;
  &:focus {
    outline: none;
  }
}

.row {
  margin: 0px;
  margin-bottom: 20px;
  width: 100%;
}

html::-webkit-scrollbar-thumb {
  background: rgb(167, 161, 161);
  border-radius: 5px;
}
html::-webkit-scrollbar {
  width: 5px;
}

div::-webkit-scrollbar-thumb {
  background: rgb(167, 161, 161);
  border-radius: 5px;
}
div::-webkit-scrollbar {
  width: 5px;
}

tbody::-webkit-scrollbar-thumb {
  background: rgb(167, 161, 161);
  border-radius: 5px;
}
tbody::-webkit-scrollbar {
  width: 5px;
}

thead::-webkit-scrollbar-thumb {
  background: rgb(167, 161, 161);
  border-radius: 5px;
}
thead::-webkit-scrollbar {
  width: 5px;
}

.MuiTablePagination-selectLabel .MuiTablePagination-displayedRows {
  padding-top: 10px;
}
// @media (min-width: 576px){
//   .modal-dialog {
//       // max-width: 800px;
//       margin: 1.75rem auto;
//   }
// }  
// @media (min-width: 1400px){
//   .modal-dialog {
//       max-width: 1500px;
//       margin: 1.75rem auto;
//   }
// } 
