.custom-chart {
    height: 87vh;
}

@media (max-width: 1400px) {
    .custom-chart {
        height: 84vh !important;
    }
}

@media (max-width: 1650px) {
    .custom-chart {
        height: 85.5vh;
    }
}

@media (min-width: 1800px) {
    .custom-chart {
        height: 87vh;
    }
}