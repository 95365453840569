.App {
    // background: $c-background;
    height: 100vh;
}

.container {
    max-width: 100% !important;
    height: 100vh !important;
    padding: 0px 0px !important;
}

.sidebar:hover {
    color: #FBBC05
}

.sub-menu:hover {
    color: "black"
}

.menu-app {
    padding-left: 55px;
}

.left-menu {
    a:hover {
        color: #FBBC05
    }
}
@media (max-width: 300px){
    .logo_responsive{
        display: none!important;
    }
}

@media (min-width: 1200px) {
    .menu-app {
        padding-left: 80px;
    }
    .container {
        height: 85vh !important;
    }

}
@media (min-width: 1600px){
    .container{
        height: 93vh!important;
    }
}

@media (min-width: 1800px) {
    .menu-app {
        padding-left: 35px;
    }
}


