@media only screen and (min-width: 1000px) {
    .el-title {
        display: block;

        .image-group {
            text-align: center;
        }
        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            display: flex;
            align-items: flex-end;
            text-align: center;
            color: #EA4335;
        }
    }

}

@media only screen and (min-width: 1600px) {
    .el-title {
        display: block;

        .image-group {
            text-align: center;
        }

        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            display: flex;
            align-items: flex-end;
            text-align: center;
            color: #EA4335;
        }
    }
}

@media only screen and (min-width: 1920px) {
    .el-title {
        display: flex;
        justify-content: center;

        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            display: flex;
            align-items: flex-end;
            text-align: center;
            color: #EA4335;
        }
    }
}
@media only screen and (max-width: 992px) { 
    .image-left {
        display: none;
        height: 0 !important;
    }
    .el-right {
        width: 100% !important;
        height: 100vh;
    }
    .el-title {
        display: block;

        .image-group {
            text-align: center;
        }

        .title {
            margin-top: 10px;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            color: #EA4335;
        }
    }
}
.login {
    display: flex;

    .image-left {
        width: 70%;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .el-right {
        width: 30%;
        padding: 0 25px;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

        .box-login {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .text-label {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                display: flex;
                align-items: flex-end;
                color: #4285F4;

            }

            .option {
                display: flex;
                align-items: center;

                .forget-password {
                    font-style: italic;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 21px;
                    text-decoration-line: underline;
                    color: #4285F4;
                }
            }

            .button-login {
                width: 178px;
                height: 55px;
                border-radius: 10px;
            }
        }
    }
}

.da_si {
    background-image: url('../../../Asset/images_groups/ho_da_si.jpg');
}

.da_ton {
    background-image: url('../../../Asset/images_groups/ho_da_ton.jpg');
}

.duong_dong {
    background-image: url('../../../Asset/images_groups/ho_duong_dong.jpg');
}

.song_may {
    background-image: url('../../../Asset/images_groups/ho_song_may.jpg');
}

.song_luy {
    background-image: url('../../../Asset/image-login.png');
}

.doi_ong_truong {
    background-image: url('../../../Asset/images_groups/doi_ong_tuong.jpg');
}

.default {
    background-image: url('../../../Asset/images_groups/default.jpg');
}