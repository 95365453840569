.el-button {
    height: 7vh;
    align-items: center;

    .create-button {
        padding-right: 2%;
    }
}

.table-zone {
    height: 81vh;
    padding: 0 2%;
}

.dialog-title-zone {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;

    color: #4285F4;
    align-items: center;
    text-align: center;
}

@media only screen and (min-width: 1366px) {
    .table-zone {
        height: 78vh;
    }
}

@media only screen and (min-width: 1600px) {
    .table-zone {
        height: 80vh;
    }
}

@media only screen and (min-width: 1920px) {
    .table-zone {
        height: 81vh;
    }
}