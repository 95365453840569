.Map-Right-Container {
    min-height: 300px;
    .leaflet-popup-content {
        min-width: 170px;
        max-height: 160px;
        overflow: auto;
        .title {
            color: #1962a9;
            font-weight: 700;
            border-bottom: 1px gray solid;
        }
        .item {
            padding-top: 4px;
            line-height: 23px;
            .item-title {
                font-weight: bold;
            }
        }
    }
}
