.detail-group{
    height: 94vh;
}


@media only screen and (min-width: 1366px){
    .detail-group{
        height: 92vh;
    }
}

@media only screen and (min-width: 1600px){
    .detail-group{
        height: 93vh;
    }
}
@media only screen and (min-width: 1920px){
    .detail-group{
        height: 94vh;
    }
}