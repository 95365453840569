$c-text: #3c3c3c;
$c-text-head: white;
$c-border: #c3c3c3;
$c-background: #C4C4C4;
$c-background-card: #ffffff;
$c-background-head: #343a40;
$c-background-head-hover: #028E93;
$c-main-color: #28b76b; // màu chủ đạo toàn bộ hệ thống

////BUTTON///
$c-text-btn: #ffffff;
$c-secondary: #28b76b;
$c-danger: #d26d69;
$c-secondary-hover: #219859;
$c-danger-hover: #c9504c;
$border: solid 1px $c-border;
$border-radius: 6px;
$transition-btn-hover: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);

$c-table-text-header: #fff;
$c-table-header: #28b76b;
$c-table-row: #f4fcf6;
//$c-table-row-hover: #e7f4eb;
$c-table-row-hover: #f4fcf6; //màu chuẩn
$c-table-row-selected: #e7f4eb; //màu được chọn
$c-table-row-succes: #fdf6e5;
$c-table-row-wait: #fbf3f2;
$transition-table-hover: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);


$c-hue: 211;
$c-sat: 100;
$box-shadow: 5px 5px 10px -5px rgba(0, 0, 0,  0.12), 0px 2px 2px 0px rgba(0, 0, 0,  0.1), 0px 1px 5px 0px rgba(0 ,0, 0,  0.08);

$s-text: 13px;
$s-text-head: 16px;






