.MenuApp {
    // height: 51px;
    width: 97.5%;
    background: white;
    border-radius: 6px;
    .text-zone{
        width: 325px;
        height: 31px;
        margin-left: 50px;
        margin-top: 16px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: flex-end;
        color: #4285F4;
    }
}

.ctn-export {
    position: relative;
    button {
        font-size: 12px;
        position: absolute;
        width: 110px;
        left: 20px;
        top: -5px;
        padding: 4px 5px 3px;
    }
    button:hover {
        font-weight: bold;
    }
}