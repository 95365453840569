/* ==========================================================================
   Table
   ========================================================================== */

.table {
    margin-bottom: 1rem;
    width: 100%;
    text-align: left;
    color: #212529;
  th,
  td {
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: top;
  }
  thead {
    color: $c-table-text-header;
    th {
      border-top: 0px;   
      border-bottom: 2px solid #dee2e6;
      background-color: $c-table-header;
      vertical-align: bottom;      
    }
  }
  tbody {
    width: 100%;
  }
  tbody tr:first-child td {
    border-top: 0px;
  }

  .table-bordered {
    border: 1px solid #dee2e6;
    th,
    td {
      border: 1px solid #dee2e6;
    }
    thead {
      th, td{
        border-bottom-width: 2px;
      }
    }
  }
  .bodyTable {
    max-height: 500px;
    min-height: 500px;
    text-align: center;
    background-color: white;
    }
    tbody::-webkit-scrollbar-thumb {
        background: rgb(167, 161, 161);
        border-radius: 5px;
    }
    tbody::-webkit-scrollbar {
        width: 5px;
    }

  ///// table
  .table-reponsive{
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive > .table-bordered {
      border: 0;
  }
}
  
  .table.table-head-fixed thead tr:nth-child(1) th {
    position: -webkit-sticky;
    position: sticky;    
    top: 0px;
    z-index: 10;
    margin-top: -1px;
    border-bottom: 0;
    box-shadow: inset 0 1px 0 #dee2e6, inset 0 -1px 0 #dee2e6;
  } 
  .table-striped tbody tr:nth-of-type(even) {
    background-color: $c-table-row;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
  }
  .table tbody tr:hover{
    background-color: $c-table-row-hover;
  }